/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar, Card, Divider, Grid, ListItem
} from '@mui/material';
import {
  MapContainer, TileLayer, Marker, Popup
} from 'react-leaflet';
// import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';

import { useAuth } from '../../contexts/auth';
// import customerService from '../../services/customerService';
import subsidiaryService from '../../services/subsidiaryService';
import Loading from '../../components/loading';
import driverService from '../../services/driverService';
import { phoneNumber, toBrazilDateTime } from '../../components/mask';
// import customerMarker from '../../assets/images/customer-marker.png';
import subsidiaryMarker from '../../assets/images/subsidiary-marker.png';
import helm from '../../assets/images/helm.png';
import helmGreen from '../../assets/images/helm-green.png';
import helmRed from '../../assets/images/helm-red.png';
// import { onSnapshot } from '../../config/firebase';
import StatusCallHistory from '../../models/enums/statusCallHistory';
import { socket } from '../../services/socketioService';
// import { DataContext } from '../../contexts/dataContext';
// eslint-disable-next-line import/no-extraneous-dependencies, import/order

interface Row {
  id: string;
  description: string;
  category: string;
  html: string;
  photoURL: string;
  phoneNumber: string | null;
  active: boolean;
  busy: boolean;
  lat: number | null;
  lng: number | null;
  updatedAt: Date | null;
  isLink: boolean;
  call: any,
  stationary: boolean
}

interface OwnProps {
  subsidiaryId: string;
  title: string;
}

export default function page(props: OwnProps) {
  let { subsidiaryId } = useAuth();
  let latitude = 0;
  let longitude = 0;
  const { user } = useAuth();
  if (props.subsidiaryId) subsidiaryId = props.subsidiaryId;

  const [isLoading, setIsLoading] = React.useState(true);
  const [subsidiaries, setSubsidiaries] = React.useState<Row[]>([]);
  const [driverSelectedId, setDriverSelectedId] = React.useState<string | null>(null);
  const [zoom, setZoom] = React.useState(4.5);
  const [center, setCenter] = React.useState({ lat: -11.840096772124648, lng: -51.95084271385832 });
  // const { drivers, setDrivers } = useContext(DataContext);
  const [drivers, setDrivers] = React.useState<any[]>([]);
  let driversOnMap: any = [];

  const subsidiaryIcon = L.icon({
    iconUrl: subsidiaryMarker,
    iconSize: [64, 64],
    iconAnchor: [31, 63]
  });
  const availableIcon = L.icon({
    iconUrl: helm,
    iconSize: [28, 28],
    iconAnchor: [20, 20],
    popupAnchor: [0, -45]
  });

  const acceptedIcon = L.icon({
    iconUrl: helmGreen,
    iconSize: [26, 26],
    iconAnchor: [22, 18],
    popupAnchor: [0, -45]
  });

  const busyIcon = L.icon({
    iconUrl: helmRed,
    iconSize: [26, 26],
    iconAnchor: [22, 20],
    popupAnchor: [0, -45]
  });

  const divStyle = { color: 'white', backgroundColor: '#d1d2db' };

  const getSubsidiaries = () => {
    let result;
    if (subsidiaryId) {
      result = subsidiaryService.getByIdAsync(subsidiaryId!).then(async (data) => {
        if (data.address.location) {
          latitude = data.address.location.latitude;
          longitude = data.address.location.longitude;
          setCenter({ lat: latitude, lng: longitude });
          setSubsidiaries([{
            id: data.id,
            description: data.description,
            category: '',
            html: '',
            active: true,
            busy: false,
            lat: latitude,
            lng: longitude,
            phoneNumber: null,
            photoURL: '',
            updatedAt: null,
            isLink: false,
            call: null,
            stationary: false
          }]);
          setZoom(13);
        }
      });
    } else {
      // TODO: Implement infinit scroll
      result = subsidiaryService.getByPaginationAsync(1, 200).then((subs) => {
        setSubsidiaries(subs.data.filter((x: any) => x.active));
      });
    }
    return result;
  };

  const prepareOnLineDrivers = async () => {
    const allDriversChannel = `${subsidiaryId}:alldrivers`;
    const driversDeleteChannel = `${subsidiaryId}:drivers:delete`;

    const prepareSocket = async () => {
      // eslint-disable-next-line no-console
      console.log('prepareSocket');
      const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
      socket.on('connect', () => {});
      if (socket.connected) {
        // eslint-disable-next-line no-console
        console.log('Disconnecting...');
        socket.disconnect();
        await delay(1000);
        socket.connect();
      } else {
        socket.connect();
      }
    };

    const driverUpdateEvent = (newValue: any) => {
      const listUpdated: any = driverService.updateOnlineDriversList(driversOnMap, newValue);
      driversOnMap = listUpdated;
      setDrivers(_.sortBy(driversOnMap, (x) => x.description));
    };

    const driverDeleteEevent = (newValue: any) => {
      const listUpdated: any = driverService.updateOnlineDriversList(driversOnMap, newValue);
      setDrivers(_.sortBy(listUpdated, (x) => x.description));
    };

    const loadDriversFromRedis = async (subsidiary: string | null, userUid: string | null) => {
      const listFromRedis = await driverService.getOnlineFromRedis(subsidiary, userUid, latitude, longitude);
      const preparedList = driverService.prepareDriverList(listFromRedis);
      driversOnMap = _.sortBy(preparedList, (x) => x.description);
      return driversOnMap;
    };

    // eslint-disable-next-line react/no-this-in-sfc
    // const removeNoActivity = () => {
    //   const listUpdated = driverService.removeFromOnlineDriversList(driversOnMap);
    //   driversOnMap = listUpdated;
    //   setDrivers(_.sortBy(listUpdated, (x) => x.description));
    // };

    const subId = subsidiaryId || null;
    const userUid = user?.uid || null;
    loadDriversFromRedis(subId, userUid).then(async (preparedList: any) => {
      driversOnMap = preparedList;
      setDrivers(_.sortBy(preparedList, (x) => x.description));
      socket.on(allDriversChannel, driverUpdateEvent);
      socket.on(driversDeleteChannel, driverDeleteEevent);
      prepareSocket();
      setIsLoading(false);
    });
    // setInterval(removeNoActivity, 1000 * 30);
  };

  useEffect(() => {
    setIsLoading(true);
    // setDrivers([]);
    // eslint-disable-next-line no-console
    console.log('useEffect');
    Promise.all([getSubsidiaries()]).then(async () => {
      await prepareOnLineDrivers();
    });
    return () => {};
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={7} md={9}>
        <Card className="card-box mb-4">
          <div className="card-header py-3">
            <div className="card-header--title font-size-lg">
              {props.title ?? 'Entregadores em tempo real'}
            </div>
          </div>
          <div className="w-100" style={{ height: '600px' }}>
            {isLoading
              ? <Loading />
              : (
                <MapContainer center={center} scrollWheelZoom={false} zoom={zoom} style={{ height: '600px' }}>
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  />
                  {subsidiaries.map((s: Row) => (
                    <Marker
                      key={s.id}
                      position={{ lat: s.lat ?? 0, lng: s.lng ?? 0 }}
                      icon={subsidiaryIcon}
                      title="Filial"
                      zIndexOffset={0}
                    />
                  ))}
                  {/* <MarkerClusterGroup>
                    {customers.map((x: Row) => (
                      <Marker
                        key={x.id}
                        position={{ lat: x.lat ?? 0, lng: x.lng ?? 0 }}
                        icon={customerIcon}
                        title={x.description}
                        zIndexOffset={1}
                      >
                        <Popup>
                          <div className="d-flex align-items-center">
                            <div className="avatar-icon-wrapper avatar-icon-lg  mr-2">
                              <div className="avatar-icon">
                                <img alt={x.phoneNumber ?? ''} src={x.photoURL} />
                              </div>
                            </div>
                            <div>
                              <span className="font-weight-bold text-black" title="">
                                {x.isLink
                                  ? <a target="_blank" href={`/drivers/${x.id}`} rel="noreferrer">{x.description}</a>
                                  : <p>{x?.description}</p>}
                              </span>
                              <span className="text-black-50 d-block">
                                <div dangerouslySetInnerHTML={{ __html: x.html }} />
                              </span>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    ))}
                  </MarkerClusterGroup> */}
                  {drivers?.map((x) => (
                    <Marker
                      key={x.id}
                      position={{ lat: x.lat ?? 0, lng: x.lng ?? 0 }}
                      // eslint-disable-next-line no-nested-ternary
                      icon={x.call?.id === null ? availableIcon : (x.call?.status === StatusCallHistory.Accepted ? acceptedIcon : busyIcon)}
                      zIndexOffset={-1}
                      eventHandlers={{
                        click: () => {
                          setDriverSelectedId(x.id);
                        }
                      }}
                    >
                      <Popup onClose={() => setDriverSelectedId(null)}>
                        <div className="d-flex align-items-center">
                          <div className="avatar-icon-wrapper avatar-icon-lg  mr-2">
                            <div className="avatar-icon">
                              <img alt={x.phoneNumber ?? ''} src={x.photoURL} />
                            </div>
                          </div>
                          <div>
                            <span className="font-weight-bold text-black" title="">
                              {x.isLink
                                ? <a target="_blank" href={`/drivers/${x.id}`} rel="noreferrer">{x.description}</a>
                                : <p>{x.description}</p>}
                            </span>
                            <span className="text-black-50 d-block">
                              <div dangerouslySetInnerHTML={{ __html: x.html }} />
                            </span>
                          </div>
                        </div>
                      </Popup>
                    </Marker>
                  ))}
                </MapContainer>
              )}
          </div>
          <div className="d-block text-black-50 font-size-sm ml-2 mr-1">
            <p>
              É importante estar ciente de que a confiabilidade das informações exibidas no mapa e na lista podem ser afetadas pela qualidade do sinal de internet do celular do entregador.
              Sinais móveis variam em força e estabilidade dependendo de diversos fatores, como localização geográfica, congestionamento da rede e condições climáticas.
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <Card className="card-box">
          <div className="card-header py-3">
            <div className="card-header--title font-size-lg">Últimas atualizações</div>
          </div>
          <div className="scroll-area shadow-overflow" style={{ height: '600px' }}>
            <PerfectScrollbar>
              {drivers?.map((d, i) => (
                <div key={d.id}>
                  <ListItem button className="align-box-row" style={driverSelectedId === d.id ? divStyle : undefined}>
                    <div className="align-box-row w-100">
                      <Avatar alt="" src={d.photoURL} className="mr-2" title={phoneNumber(d.phoneNumber)} />
                      <div>
                        <span className={`font-weight ${d.call?.id === null ? 'text-black' : (d.call?.status === StatusCallHistory.Accepted ? 'text-success' : 'text-danger')}`} title={d.busy ? 'Ocupado' : 'Disponível'}>
                          {d.description}
                        </span>
                        <span className={`d-block ${d.call?.id === null ? 'text-black' : (d.call?.status === StatusCallHistory.Accepted ? 'text-success' : 'text-danger')}`} title={d.busy ? 'Ocupado' : 'Disponível'}>
                          {d.category}
                        </span>
                        <span className={`d-block ${driverSelectedId === d.id ? '' : 'text-black-50'}`} title="Última atualização">
                          {toBrazilDateTime(d.updatedAt)}
                        </span>
                        <span className="d-block text-black-50" title="Status">
                          {d.stationary ? 'Estacionado' : 'Em movimento'}
                        </span>
                      </div>
                      <div className="ml-auto card-hover-indicator align-self-center">
                        <FontAwesomeIcon icon={['fas', 'chevron-right']} className="font-size-md" />
                      </div>
                    </div>
                  </ListItem>
                  {drivers.length - 1 !== i && <Divider />}
                </div>
              ))}
            </PerfectScrollbar>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
