/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  IconButton,
  Card,
  Button,
  Tooltip,
  Dialog,
  Divider,
  ListItem,
  List,
  Fab,
  Switch,
  Skeleton
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import CountUp from 'react-countup';
import _ from 'lodash';

import { getErrorMessage } from '../../../../config/firebase';
import Subsidiary from '../../../../models/interfaces/subsidiary';
import tariffService from '../../../../services/tariffService';
import Tariff from '../../../../models/interfaces/tariff';
import { CategoryDriverText } from '../../../../models/enums/categoryDriver';
import { calculationMethodText } from '../../../../models/enums/calculationMethod';
import subsidiaryService from '../../../../services/subsidiaryService';
import userService from '../../../../services/userService';
import Loading from '../../../../components/loading';

import processImg from '../../../../assets/images/illustrations/process.svg';
import driverService from '../../../../services/driverService';
import callService from '../../../../services/callService';
import Call from '../../../../models/interfaces/call';
import { phoneNumber } from '../../../../components/mask';
import { getFirstDate, getLastDate } from '../../../../utils/dateHelper';
import LineCard from '../../../dashboard/components/lineCard';
import customerService from '../../../../services/customerService';

interface ParamTypes {
  id: string
}

interface Employee {
  id: string,
  name: string,
  photoURL: string,
}

const page = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<ParamTypes>();
  const [model, setModel] = React.useState<Subsidiary | undefined>(undefined);
  const [tariffs, setTariffs] = React.useState<Tariff[]>([]);
  // const [callsLine, setCallsLine] = React.useState<Call[]>([]);
  const [callsCount, setCallsCount] = React.useState(0);
  const [employees, setEmployees] = React.useState<Employee[]>([]);
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmTariffModal, setConfirmTariffModal] = React.useState(false);
  const [driversTotal, setDriversTotal] = React.useState<number | undefined>(undefined);
  const [customersTotal, setCustomerstotal] = React.useState<number | undefined>(undefined);
  // const [isLoadingLine, setIsLoadingLine] = React.useState(true);
  const [billingTotal, setBillingTotal] = React.useState<number | undefined>(undefined);
  const [isLoadingTariffs, setIsLoadingTariffs] = React.useState(true);
  // const daysLimit = 7;

  const toggleModal = () => setConfirmModal(!confirmModal);
  const toggleTariffModal = () => setConfirmTariffModal(!confirmTariffModal);
  const elementToDelete = useRef('');

  useEffect(() => {
    subsidiaryService.getByIdAsync(id).then((item) => {
      if (item) {
        setModel({
          id: item.id,
          uid: item.uid,
          owner: item.owner,
          description: item.description,
          email: item.email,
          address: item.address,
          phoneNumber: item.phoneNumber,
          createdAt: item.createdAt,
          disabledAt: item.disabledAt,
          settings: item.settings,
          cnpj: item.cnpj
        });

        // TODO: Implement infinite scroll on list
        userService.getEmployeesByPaginationAsync(id, 1, 200).then((result) => {
          setEmployees(result.data.map((x: any) => ({
            id: x.id,
            name: x.name,
            photoURL: ''
          })));
        });
      } else {
        history.push('/404');
      }
    });

    callService.getReportCounter(id, getFirstDate(), getLastDate()).then((result) => {
      const total = _.sumBy(result, (o) => o.value) ?? 0;
      setBillingTotal(total);
    });

    // Aldenor desativou essa funcionalidade
    // callService.countLastDays(daysLimit, id).then((result) => { setCallsLine(result); }).finally(() => setIsLoadingLine(false));

    // Esta estourando a memoria dos navegadores
    // callService.count(id).then((result) => { setCallsCount(result); });

    tariffService.getAllAsync(id).then((result) => { setTariffs(result); }).then(() => setIsLoadingTariffs(false));

    driverService.countByCurrentMonth(id).then((result) => { setDriversTotal(result); });

    customerService.countByCurrentMonth(id).then((result) => { setCustomerstotal(result); });
  }, []);

  const changeStatus = () => {
    if (!model) return;

    const value = model.disabledAt ? null : new Date();
    subsidiaryService.deleteAsync(id, value).then(() => {
      setModel({ ...model, disabledAt: value });
      setConfirmModal(false);
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    });
  };

  const deleteTariff = (tariffId: string, isDisabled: boolean) => {
    tariffService.changeStatus(tariffId, isDisabled).then(() => {
      const result = [...tariffs!];
      const elementsIndex = tariffs!.findIndex((x) => x.id === tariffId);
      result[elementsIndex] = { ...result[elementsIndex], disabledAt: isDisabled ? new Date() : null };
      setTariffs(result);
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }).finally(() => setConfirmTariffModal(false));
  };

  return (
    <>
      <Card className="card-box mb-4">
        {!model ? <Loading height="84px" /> : (
          <>
            {model.disabledAt && (
              <span className="ribbon-angle ribbon-angle--top-left ribbon-danger">
                <small>Desativada</small>
              </span>
            )}
            <div>
              <div className="d-block p-3 d-md-flex justify-content-between align-items-center text-center text-md-left">
                <div className="d-flex flex-md-row flex-column align-items-center">
                  <div className="font-size-lg font-weight-bold">{model.description}</div>
                  <div className="mx-3 d-none d-md-block">
                    <div className="divider-v position-relative" />
                    <div className="divider-v position-relative" />
                  </div>
                  <div className="pr-3">
                    <span className="font-size-lg">
                      {model.owner.name}
                    </span>
                    <br />
                    <span className="text-black-50">
                      {phoneNumber(model.owner.phoneNumber)}
                    </span>
                  </div>
                </div>
                <div className="d-block d-md-flex align-items-center">
                  <Button size="small" variant="outlined" color="primary" className="font-weight-bold px-3" component={Link} to={`${model.id}/edit`}>
                    Editar
                  </Button>
                  <Switch
                    checked={!model.disabledAt}
                    onChange={model.disabledAt ? changeStatus : toggleModal}
                    color="primary"
                    title={model.disabledAt ? 'Inativo' : 'Ativo'}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Card>

      <Grid container spacing={{ xs: 1, md: 3 }}>
        <Grid item xs={12}>
          <Card className="card-box mb-3">
            <Grid container spacing={0}>
              <Grid item xs={6} sm={4} md={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className="d-block border-0 w-100 rounded-0 py-4 px-1 text-warning"
                  onClick={() => { history.push(`${id}/revenue`); }}
                >
                  <FontAwesomeIcon icon={['fas', 'money-bill']} className="h2 d-block mb-2 mx-auto mt-1 text-warning" />
                  <div className="font-weight-bold text-black">
                    {billingTotal !== undefined
                      ? <CountUp start={0} end={billingTotal} duration={2} separator="." decimals={2} decimal="," prefix="R$ " suffix="" />
                      : <Skeleton variant="text" />}
                  </div>
                  <div className="font-size-sm mb-1 text-black-50">Receita/mês</div>
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className="d-block border-0 w-100 rounded-0 py-4 px-1 text-warning"
                  onClick={() => { history.push(`${id}/drivers`); }}
                >
                  <FontAwesomeIcon icon={['fas', 'user-tie']} className="h2 d-block mb-2 mx-auto mt-1 text-warning" />
                  <div className="font-weight-bold text-black">
                    {driversTotal !== undefined
                      ? <CountUp start={0} end={driversTotal} duration={2} separator="." />
                      : <Skeleton variant="text" />}
                  </div>
                  <div className="font-size-sm mb-1 text-black-50">Novos entregadores/mês</div>
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className="d-block border-0 w-100 rounded-0 py-4 px-1 text-warning"
                  onClick={() => { history.push(`${id}/customers`); }}
                >
                  <FontAwesomeIcon icon={['fas', 'building']} className="h2 d-block mb-2 mx-auto mt-1 text-warning" />
                  <div className="font-weight-bold text-black">
                    {customersTotal !== undefined
                      ? <CountUp start={0} end={customersTotal} duration={2} separator="." />
                      : <Skeleton variant="text" />}
                  </div>
                  <div className="font-size-sm mb-1 text-black-50">Novos clientes/mês</div>
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className="d-block border-0 w-100 rounded-0 py-4 px-1 text-warning"
                  title="Quantidade de pedidos finalizados e cancelados no mês atual"
                  disabled={model == null}
                  onClick={() => { history.push(`${model?.uid}/deliveries`); }}
                >
                  <FontAwesomeIcon icon={['fas', 'people-carry']} className="h2 d-block mb-2 mx-auto mt-1 text-warning" />
                  <div className="font-weight-bold text-black">
                    {/* <CountUp start={0} end={callsCount} duration={2} separator="." /> */}
                    Entregas
                  </div>
                  <div className="font-size-sm mb-1 text-black-50">Últimas 24h</div>
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className="d-block border-0 w-100 rounded-0 py-4 px-1 text-warning"
                  onClick={() => { history.push(`${id}/billing`); }}
                >
                  <FontAwesomeIcon icon={['fas', 'wallet']} className="h2 d-block mb-2 mx-auto mt-1 text-warning" />
                  <div className="font-weight-bold text-black">
                    Faturamento
                  </div>
                  <div className="font-size-sm mb-1 text-black-50">Por período</div>
                </Button>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className="d-block border-0 w-100 rounded-0 py-4 px-1 text-warning"
                  disabled={model == null}
                  onClick={() => { history.push(`${model?.uid}/real-time`); }}
                >
                  <FontAwesomeIcon icon={['fas', 'map-marked-alt']} className="h2 d-block mb-2 mx-auto mt-1 text-warning" />
                  <div className="font-weight-bold text-black">
                    Mapa de entregadores
                  </div>
                  <div className="font-size-sm mb-1 text-black-50">Em tempo real</div>
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 1, md: 3 }}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card className="card-box">
            <div className="card-header">
              <div className="card-header--title">
                <b>Funcionários</b>
              </div>
              <div className="card-header--actions">
                <Fab size="small" color="primary" disabled>
                  <FontAwesomeIcon icon={['fas', 'plus']} className="font-size-sm" />
                </Fab>
              </div>
            </div>
            <List>
              <div className="scroll-area shadow-overflow">
                <PerfectScrollbar>
                  {employees?.map((x: any, i: number) => (
                    <div key={x.id}>
                      <ListItem button className="align-box-row" onClick={() => { history.push(`/users/${x.id}`); }}>
                        <div className="align-box-row w-100">
                          <div className="mr-3">
                            <div className="bg-grow-early text-center text-white font-size-xl d-50 rounded-circle">
                              <FontAwesomeIcon icon={['far', 'user']} />
                            </div>
                          </div>
                          <div>
                            <div className="font-weight-bold text-primary d-block">
                              {x.name}
                            </div>
                            {/* <small className="text-success">
                              <FontAwesomeIcon icon={['fas', 'chevron-up']} className="text-success mr-1" />
                              <span>15.4% increase</span>
                            </small> */}
                          </div>
                          <div className="ml-auto card-hover-indicator align-self-center">
                            <FontAwesomeIcon icon={['fas', 'chevron-right']} className="font-size-md" />
                          </div>
                        </div>
                      </ListItem>
                      {i !== (tariffs.length - 1) && <Divider />}
                    </div>
                  ))}

                </PerfectScrollbar>
              </div>
            </List>
            <div className="card-footer bg-light text-center">
              <Button size="small" color="primary" variant="contained" disabled>Visualizar todos</Button>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={8}>
          <Card className="card-box overflow-hidden mb-4">
            <div className="card-header">
              <div className="card-header--title">
                <b>Tarifas</b>
              </div>
              <div className="card-header--actions">
                <Tooltip arrow title="Nova tarifa" onClick={() => { history.push(`/subsidiaries/${id}/tariffs/create`); }}>
                  <Fab size="small" color="primary">
                    <FontAwesomeIcon icon={['fas', 'plus']} className="font-size-sm" />
                  </Fab>
                </Tooltip>
              </div>
            </div>
            {isLoadingTariffs ? <Loading /> : (
              <>
                {tariffs?.length ? (
                  <List>
                    <PerfectScrollbar className="scroll-area">
                      {tariffs?.map((x: Tariff, i: number) => (
                        <div key={x.id}>
                          <ListItem className="hover-show-hide-container d-flex justify-content-between align-items-center py-3 border-0">
                            <span className={`badge-circle mt-2 mr-2 align-self-start badge badge-${x.disabledAt ? 'danger' : 'success'}`}>
                              Basic
                            </span>
                            <div className="font-weight-bold flex-grow-1">
                              <div className="text-second font-size-lg">{x.description}</div>
                              <span className="opacity-8">
                                <FontAwesomeIcon icon={['far', 'user']} className="mr-1" />
                                {CategoryDriverText[x.categoryDriver]}
                              </span>
                            </div>
                            <div className="text-right hover-hide-wrapper">
                              <div className="font-weight-bold text-second">{calculationMethodText[x.calculationMethod]}</div>
                              <span className="opacity-7">por km</span>
                            </div>
                            <div className="text-right hover-show-wrapper">
                              <Tooltip arrow title="Editar" onClick={() => { history.push(`/subsidiaries/${id}/tariffs/${x.id}/edit`); }}>
                                <IconButton className="bg-white text-first d-40 rounded-circle ml-1">
                                  <FontAwesomeIcon icon={['far', 'edit']} className="font-size-md mx-auto" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={x.disabledAt ? 'Habilitar' : 'Desabilitar'}
                                onClick={() => {
                                  if (x.disabledAt) {
                                    deleteTariff(x.id, false);
                                  } else {
                                    elementToDelete.current = x.id;
                                    toggleTariffModal();
                                  }
                                }}
                              >
                                <IconButton className={`bg-white text-${x.disabledAt ? 'success' : 'danger'} d-40 rounded-circle ml-1`}>
                                  <FontAwesomeIcon icon={['fas', x.disabledAt ? 'check' : 'times']} className="font-size-md mx-auto" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </ListItem>
                          {i !== (tariffs.length - 1) && <Divider />}
                        </div>
                      ))}
                    </PerfectScrollbar>
                  </List>
                ) : (
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="pr-5">
                        <div className="bg-first text-center text-white font-size-xl d-50 rounded-circle mb-4 mt-1">
                          <FontAwesomeIcon icon={['far', 'bell']} />
                        </div>
                        <h4 className="font-size-xl font-weight-bold mb-2">
                          Nenhuma informação encontrada.
                        </h4>
                      </div>
                      <div className="w-25 d-flex align-items-center">
                        <img alt="" className="d-block img-fluid" src={processImg} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* <Grid container spacing={4}>
        <LineCard rows={callsLine} daysLimit={daysLimit} isLoading={isLoadingLine} />
      </Grid> */}

      <Dialog open={confirmModal} onClose={toggleModal}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-warning text-white m-0 d-130">
              <FontAwesomeIcon
                icon={['far', 'dot-circle']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <div className="font-weight-bold font-size-lg mt-4">
            Você tem certeza?
          </div>
          <p className="mb-0 mt-2">
            Todos os usuários com acesso ao sistema Admin seram bloqueados.
          </p>
          <div className="pt-4">
            <Button
              onClick={toggleModal}
              variant="outlined"
              color="primary"
              className="mx-1"
            >
              <span className="btn-wrapper--label">Cancelar</span>
            </Button>
            <Button
              onClick={changeStatus}
              variant="outlined"
              color="primary"
              className="mx-1"
            >
              <span className="btn-wrapper--label">Sim, desativar tudo</span>
            </Button>
          </div>
        </div>
      </Dialog>

      {/* https://medium.com/javascript-in-plain-english/creating-a-confirm-dialog-in-react-and-material-ui-3d7aaea1d799 */}
      <Dialog open={confirmTariffModal} onClose={toggleTariffModal}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-warning text-white m-0 d-130">
              <FontAwesomeIcon icon={['far', 'dot-circle']} className="d-flex align-self-center display-3" />
            </div>
          </div>
          <div className="font-weight-bold font-size-lg mt-4">
            Você tem certeza?
          </div>
          <p className="mb-0 mt-2">
            Essa tarifa será removida do aplicativo.
          </p>
          <div className="pt-4">
            <Button
              onClick={toggleTariffModal}
              variant="outlined"
              color="primary"
              className="mx-1"
            >
              <span className="btn-wrapper--label">Cancelar</span>
            </Button>
            <Button
              onClick={() => deleteTariff(elementToDelete.current, true)}
              variant="outlined"
              color="primary"
              className="mx-1"
            >
              <span className="btn-wrapper--label">Sim</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default page;
