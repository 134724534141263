import React from 'react';
import {
  StyleSheet, Document, Page, View, Text
} from '@react-pdf/renderer';

import { toBrazilDate, toBrazilDateTime } from '../../../../components/mask';
import PaymentType from '../../../../models/enums/paymentType';

interface Payment{
  returnValue: number;
  type: PaymentType;
  value: number;
}

export interface RowReport {
  Id: string;
  Date: string;
  Cliente: string;
  Entregador: string;
  Destinos: string;
  Pagamento: string;
  Distancia: string;
  HasBack: boolean;
  Payment: Payment | null;
  Total: number;
}

interface OwnProps {
  data: RowReport[];
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  fileName: string;
}

export default function print(props: OwnProps) {
  const toDecimal = (value: number) => value?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const styles = StyleSheet.create({
    body: {
      padding: 10,
      paddingBottom: 60
    },
    table: {
      width: 'auto',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    colExtraSmallHeader: {
      width: '7%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colSmallHeader: {
      width: '8%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colMediumHeader: {
      width: '15%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colLargeHeader: {
      width: '33%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colExtraSmall: {
      width: '7%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colSmall: {
      width: '8%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colMedium: {
      width: '15%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colLarge: {
      width: '33%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCellHeader: {
      margin: 'auto',
      fontSize: 12,
      fontWeight: 500
    },
    tableCell: {
      fontSize: 10
    },
    tableMoneyCell: {
      fontSize: 10,
      textAlign: 'right'
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey'
    }
  });

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.body}>
        <Text style={styles.header} fixed>
          {`${toBrazilDate(props.startDate)} a ${toBrazilDate(props.endDate)}\nValores com * indicam que possuem taxa de retorno`}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.colExtraSmallHeader}>
              <Text style={styles.tableCellHeader}>Data</Text>
            </View>
            <View style={styles.colMediumHeader}>
              <Text style={styles.tableCellHeader}>Cliente</Text>
            </View>
            <View style={styles.colMediumHeader}>
              <Text style={styles.tableCellHeader}>Entregador</Text>
            </View>
            <View style={styles.colLargeHeader}>
              <Text style={styles.tableCellHeader}>Destino(s)</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Pagamento</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Distancia</Text>
            </View>
            <View style={styles.colExtraSmallHeader}>
              <Text style={styles.tableCellHeader}>Taxa de retorno</Text>
            </View>
            <View style={styles.colExtraSmallHeader}>
              <Text style={styles.tableCellHeader}>Total</Text>
            </View>
          </View>
          {props.data.map((x) => (
            <View style={styles.tableRow} key={x.Id} wrap={false}>
              <View style={styles.colExtraSmall}>
                <Text style={styles.tableCell}>{x.Date}</Text>
              </View>
              <View style={styles.colMedium}>
                <Text style={styles.tableCell}>{x.Cliente}</Text>
              </View>
              <View style={styles.colMedium}>
                <Text style={styles.tableCell}>{x.Entregador}</Text>
              </View>
              <View style={styles.colLarge}>
                <Text style={styles.tableCell}>{x.Destinos}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableCell}>{x.Pagamento}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{x.Distancia}</Text>
              </View>
              <View style={styles.colExtraSmall}>
                <Text style={styles.tableMoneyCell}>
                  {toDecimal(x.Payment?.returnValue ?? 0)}
                </Text>
              </View>
              <View style={styles.colExtraSmall}>
                <Text style={styles.tableMoneyCell}>
                  {x.HasBack ? '*' : ''}
                  {toDecimal(x.Total)}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}\n${toBrazilDateTime(new Date())}`
          )}
          fixed
        />
      </Page>
    </Document>
  );
}
