/* eslint-disable operator-linebreak */
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  GeoPoint,
  where,
  query,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  orderBy,
  limit,
  startAt,
  endAt,
  startAfter,
  onSnapshot,
  setDoc,
  serverTimestamp,
  CollectionReference
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  getMetadata
} from 'firebase/storage';
import {
  getAuth,
  updateProfile,
  sendPasswordResetEmail,
  User,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updatePassword
} from 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_ADMIN_API_KEY,
  authDomain: process.env.REACT_APP_ADMIN_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ADMIN_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ADMIN_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ADMIN_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ADMIN_APP_ID,
  measurementId: process.env.REACT_APP_ADMIN_MEASUREMENT_ID
};

const firebaseApp = initializeApp(config);

const db = getFirestore(firebaseApp);
const storage = getStorage();

export const alertCollection = collection(db, 'alerts');
export const balanceCollection = collection(db, 'balance');
export const balanceTransactionsCollection = collection(db, 'balance-transactions');
export const callCollection = collection(db, 'calls');
export const callRejectionsCollection = collection(db, 'call-rejections');
export const customerCollection = collection(db, 'clients');
export const driverCollection = collection(db, 'drivers');
export const onlineCollection = collection(db, 'online');
export const tariffCollection = collection(db, 'tariffs');
export const tariffDynamicCollection = collection(db, 'tariffsDynamics');
export const subsidiaryCollection = collection(db, 'subsidiaries');
export const userCollection = collection(db, 'users');
export const placeCollection = collection(db, 'places');
export const termCollection = collection(db, 'terms');

export const getErrorMessage = (e: any) => {
  const errorFirebase = e.response?.data;
  if (errorFirebase === undefined && e.message) return e.message;

  let message;
  switch (errorFirebase.code) {
    case 'auth/email-already-exists':
      message = 'Este email já esta sendo usado em outra conta';
      break;
    case 'auth/phone-number-already-exists':
      message = 'Este número de telefone já esta sendo usado em outra conta';
      break;
    case 'auth/invalid-phone-number':
      message =
        'Número de telefone inválido, lembre-se de usar o código do país. +55 69 9 9999-9999';
      break;
    case 'auth/invalid-password':
      message = 'A senha deve ser uma string com pelo menos 6 caracteres.';
      break;
    default:
      message = 'Ops! Aconteceu algo inesperado';
      break;
  }
  return message;
};

export const getDateNow = () => serverTimestamp();

export const createDocAsync = async (
  coll: string,
  id: string,
  data: object
) => {
  await setDoc(doc(db, coll, id), data);
};

export const createDoc = async (
  collectionRef: CollectionReference,
  data: object
) => addDoc(collectionRef, data);

export {
  getAuth,
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  updateProfile,
  GeoPoint,
  where,
  query,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  addDoc,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  orderBy,
  limit,
  startAt,
  endAt,
  startAfter,
  onSnapshot,
  listAll,
  setDoc,
  updatePassword,
  getMetadata
};
export type { User };
