/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Dictionary from '../interfaces/dictionary';
import { EnumDictionary } from '../interfaces/enumDictionary';

enum StatusCallHistory {
  Undefined = '',
  Waiting = 'waiting',
  Imported = 'imported',

  Accepted = 'accepted',

  OnGoing = 'ongoing',
  PartialComplete = 'partialComplete',
  Complete = 'complete',

  Canceled = 'canceled',
  Forwarded = 'forwarded',
  Refused = 'refused',
  Problem = 'problem'
}

const StatusCallHistoryText: EnumDictionary<StatusCallHistory, string> = {
  [StatusCallHistory.Undefined]: 'Indefinido',
  [StatusCallHistory.Waiting]: 'Aguardando',
  [StatusCallHistory.Imported]: 'Importado',
  [StatusCallHistory.Accepted]: 'Aceito',
  [StatusCallHistory.OnGoing]: 'Em trânsito',
  [StatusCallHistory.PartialComplete]: 'Parcialmente finalizado',
  [StatusCallHistory.Complete]: 'Finalizado',
  [StatusCallHistory.Canceled]: 'Cancelado',
  [StatusCallHistory.Forwarded]: 'Encaminhado',
  [StatusCallHistory.Refused]: 'Recusada',
  [StatusCallHistory.Problem]: 'Com problemas'
};

const StatusCallHistoryList: Dictionary[] = [
  { key: 'waiting', value: 'Aguardando' },
  { key: 'accepted', value: 'Aceita' },
  { key: 'ongoing', value: 'Em trânsito' },
  { key: 'complete', value: 'Finalizada' },
  { key: 'canceled', value: 'Cancelada' },
  { key: 'partialComplete', value: 'Parcialmente finalizada' },
  { key: 'imported', value: 'Importada' },
  { key: 'forwarded', value: 'Encaminhada' }
];

export const convertStatusToBrazilian = (status: string) => {
  let result = { color: '', description: '' };
  switch (status) {
    case 'waiting':
      result = {
        color: 'warning',
        description: 'Aguardando'
      };
      break;
    case 'accepted':
      result = {
        color: 'info',
        description: 'Aceita'
      };
      break;
    case 'ongoing':
      result = {
        color: 'success',
        description: 'Em trânsito'
      };
      break;
    case 'partialComplete':
      result = {
        color: 'dark',
        description: 'Parcialmente finalizada'
      };
      break;
    case 'complete':
      result = {
        color: 'dark',
        description: 'Finalizada'
      };
      break;
    case 'canceled':
      result = {
        color: 'danger',
        description: 'Cancelada'
      };
      break;
    case 'refused':
      result = {
        color: 'danger',
        description: 'Recusada pelo entregador'
      };
      break;
    case 'problem':
      result = {
        color: 'danger',
        description: 'Com problemas'
      };
      break;
    case 'imported':
      result = {
        color: 'warning',
        description: 'Importada'
      };
      break;
    case 'forwarded':
      result = {
        color: 'warning',
        description: 'Encaminhada'
      };
      break;
    default:
      result = {
        color: 'danger',
        description: '?'
      };
      break;
  }
  return result;
};

export { StatusCallHistory as default, StatusCallHistoryText, StatusCallHistoryList };
