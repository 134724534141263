import React, { useEffect } from 'react';
import {
  Fab, CircularProgress, FormControl, InputLabel, MenuItem, Select, Grid, TextField
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';

import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../../../components/styles/loadingButton';
import subsidiaryService from '../../../../services/subsidiaryService';
import {
  CallByList, CalcRoute, CallBy, FinishDelivery, DisabledPaymentToClientList, AutoComplete, CalcRouteList, FinishDeliveryList, OptionsCancelsToBlockDriver, TimeBlockDriverByCancels, TimeZones
} from '../../../../models/enums/settings';
import { SubsidiarySettings } from '../../../../models/interfaces/subsidiary';
import { useAuth } from '../../../../contexts/auth';

export default function page() {
  const history = useHistory();
  const classes = UseStyles();
  const { subsidiaryId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  if (!subsidiaryId) throw new Error('Usuário sem permissão');

  return (
    <WrapperSimple sectionHeading="Configurações">
      <Formik
        initialValues={{
          calcRoute: CalcRoute.Fulled,
          callBy: CallBy.Radius,
          fee: 0,
          disabledPaymentToClient: true,
          finishDelivery: FinishDelivery.Anywhere,
          useAutoComplete: AutoComplete.Boaztech,
          ownAutoCompleteList: false,
          cancelsToBlock: 0,
          timeBlocked: 15,
          timeZone: null,
          directionsProvider: null,
          qtyNearDriversFromQueue: null
        }}
        validationSchema={Yup.object().shape({
          calcRoute: Yup.string().required(),
          callBy: Yup.string().required(),
          fee: Yup.number().required(),
          disabledPaymentToClient: Yup.boolean().required(),
          finishDelivery: Yup.string().required(),
          ownAutoCompleteList: Yup.boolean().required(),
          cancelsToBlock: Yup.number().required(),
          timeBlocked: Yup.number().required(),
          timeZone: Yup.string().required()
        })}
        onSubmit={async (values: SubsidiarySettings, actions) => {
          await subsidiaryService.updateSettingsAsync(values, subsidiaryId).then(() => {
            setSuccess(true);
            history.goBack();
          }).catch((e) => {
            enqueueSnackbar(e.message, { variant: 'error' });
            setSuccess(false);
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<SubsidiarySettings>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;

          useEffect(() => {
            subsidiaryService.getByIdAsync(subsidiaryId).then(async (item: any) => {
              const fields = ['calcRoute', 'callBy', 'fee', 'disabledPaymentToClient', 'finishDelivery', 'ownAutoCompleteList', 'cancelsToBlock', 'timeBlocked', 'timeZone'];
              fields.forEach((field) => setFieldValue(field, item.settings[field]));
              setIsLoading(false);
            }).catch(() => {
              history.push('/404');
            });
          }, []);

          return (isLoading
            ? (
              <div className="d-flex align-items-center justify-content-center" style={{ height: '360px' }}>
                <ScaleLoader color="var(--primary)" loading />
              </div>
            )
            : (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Método de chamada dos entregadores</InputLabel>
                      <Select
                        label="Método de chamada dos entregadores"
                        name="callBy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.callBy}
                        error={Boolean(errors.callBy && touched.callBy)}
                      >
                        {CallByList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Cálculo da rota</InputLabel>
                      <Select
                        label="Cálculo da rota"
                        name="calcRoute"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.calcRoute}
                        error={Boolean(errors.calcRoute && touched.calcRoute)}
                      >
                        { CalcRouteList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Exibe tipo de pagto no cliente</InputLabel>
                      <Select
                        label="Exibe tipo de pagto no cliente"
                        name="disabledPaymentToClient"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.disabledPaymentToClient}
                        error={Boolean(errors.disabledPaymentToClient && touched.disabledPaymentToClient)}
                      >
                        { DisabledPaymentToClientList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Entregador pode finalizar em (Entrega sem retorno)</InputLabel>
                      <Select
                        label="Entregador pode finalizar em (Entrega sem retorno)"
                        name="finishDelivery"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.finishDelivery}
                        error={Boolean(errors.finishDelivery && touched.finishDelivery)}
                      >
                        {FinishDeliveryList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Inibir entregador após quantos cancelamentos?</InputLabel>
                      <Select
                        label="Inibir entregador após quantos cancelamentos?"
                        name="cancelsToBlock"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.cancelsToBlock}
                        error={Boolean(errors.cancelsToBlock && touched.cancelsToBlock)}
                      >
                        {OptionsCancelsToBlockDriver.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Tempo de espera para entregador inibido</InputLabel>
                      <Select
                        label="Tempo de espera para entregador inibido"
                        name="timeBlocked"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.timeBlocked}
                        error={Boolean(errors.timeBlocked && touched.timeBlocked)}
                      >
                        {TimeBlockDriverByCancels.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Taxa da franquia (% descontado do entregador)"
                      name="fee"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.fee && touched.fee)}
                      helperText={errors.fee && touched.fee && errors.fee}
                    />
                  </Grid>
                  {/* placesCrud */}
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Localidades</InputLabel>
                      <Select
                        label="Localidades"
                        name="timeBlocked"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.timeBlocked}
                        error={Boolean(errors.timeBlocked && touched.timeBlocked)}
                      >
                        {TimeBlockDriverByCancels.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Fuso Horário</InputLabel>
                      <Select
                        label="Fuso Horário da cidade da franquia"
                        name="timeZone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.timeZone}
                        error={Boolean(errors.timeZone && touched.timeZone)}
                      >
                        {TimeZones.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" spacing={3}>
                      <div className={classes.wrapper}>
                        <Fab
                          aria-label="save"
                          color="primary"
                          className={buttonClassname}
                          type="submit"
                        >
                          {success ? <CheckIcon /> : <SaveIcon />}
                        </Fab>
                        {isSubmitting && (
                          <CircularProgress size={68} className={classes.fabProgress} />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
