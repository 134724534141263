import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import MotorcycleIcon from '@mui/icons-material/TwoWheeler';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MapIcon from '@mui/icons-material/Map';
import AssignmentIcon from '@mui/icons-material/Assignment';

const iconsMap: any = {
  DashboardTwoToneIcon,
  PeopleIcon,
  BusinessIcon,
  MotorcycleIcon,
  SupervisorAccountIcon,
  PeopleAltIcon,
  LocationCityIcon,
  CategoryIcon,
  SettingsApplicationsIcon,
  SettingsIcon,
  SwapVerticalCircleIcon,
  MonetizationOnIcon,
  MapIcon,
  AssignmentIcon
};

export default [
  {
    label: 'Área Administrativa',
    content: JSON.parse(
      `[
        {
          "label": "Dashboards",
          "icon": "DashboardTwoToneIcon",
          "to": "/chart"
        },
        {
          "label": "Entregas do dia",
          "icon": "SwapVerticalCircleIcon",
          "to": "/deliveries"
        },
        {
          "label": "Relatório de entregas",
          "icon": "AssignmentIcon",
          "to": "/deliveries-report"
        },
        {
          "label": "Mapa",
          "icon": "MapIcon",
          "to": "/map"
        },
        {
          "label": "Franquias",
          "icon": "BusinessIcon",
          "to": "/subsidiaries"
        },
        {
          "label": "Clientes",
          "icon": "LocationCityIcon",
          "to": "/companies"
        },
        {
          "label": "Entregadores",
          "icon": "MotorcycleIcon",
          "to": "/drivers"
        },
        {
          "label": "Usuários",
          "icon": "PeopleIcon",
          "content": [
            {
              "label": "Administradores",
              "icon": "SupervisorAccountIcon",
              "description": "",
              "to": "/administrators"
            },
            {
              "label": "Gerentes",
              "icon": "PeopleAltIcon",
              "description": "",
              "to": "/managers"
            },
            {
              "label": "Funcionários",
              "icon": "PeopleAltIcon",
              "description": "",
              "to": "/users"
            }
          ]
        }
      ]`,
      (key, value) => {
        if (key === 'icon') return iconsMap[value];
        return value;
      }
    )
  }
];
