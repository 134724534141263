import * as React from 'react';
import {
  Box, Button, List, ListItem, ListItemText, DialogTitle, DialogContent,
  DialogActions, Dialog, RadioGroup, Radio, FormControlLabel,
  ListItemAvatar, Avatar, Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { GeoPoint } from 'firebase/firestore';
import haversine from 'haversine';

import CategoryDriver, { CategoryDriverText } from '../../../models/enums/categoryDriver';
import { phoneNumber } from '../../../components/mask';
import driverService from '../../../services/driverService';
import AlertDialog from '../../../components/alertDialog';
import callService from '../../../services/callService';

interface Driver {
  id: string;
  name: string;
  phone: string;
  photo: string;
  category: CategoryDriver;
  distance: number
}

interface DriverOnline extends Driver {
  isBusy: boolean;
}

interface OwnProps {
  callId: string;
  pickupLocation: GeoPoint | null;
  driver: Driver | null;
  subsidiaryId: string | null | undefined;
  status: string;
  isEnabled: false | undefined;
}

interface Option {
  key: string;
  value: string;
  disabled: boolean;
}

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  options: Option[]
  // eslint-disable-next-line no-unused-vars
  onClose: (value?: string) => void;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  // eslint-disable-next-line react/destructuring-assignment
  if (props?.options?.length === 0) return null;
  const {
    onClose, value: valueProp, open, options, ...other
  } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  // React.useEffect(() => {
  //   console.log('Ola');
  //   console.log(open);
  //   if (!open) {
  //     setValue(valueProp);
  //   }
  // }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => { onClose(); };
  const handleOk = () => { onClose(value); };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  if (!options.length) {
    return <AlertDialog open={open} handleClose={handleCancel} message="Nenhum entregador disponível no momento" />;
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Entregadores</DialogTitle>
      <DialogContent dividers>
        <p>
          *Distância do entregador para o local de coleta
        </p>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="drivers"
          name="drivers"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel value={option.key} key={option.key} control={<Radio />} label={option.value} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>Fechar</Button>
        <Button onClick={handleOk} disabled={value === valueProp}>Encaminhar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ConfirmationDialog(props: OwnProps) {
  const {
    callId, pickupLocation, driver, subsidiaryId, status, isEnabled
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<DriverOnline[]>([]);

  const handleClickListItem = () => {
    driverService.getAllOnline(subsidiaryId).then((result: any[]) => {
      const endCoordinate = { longitude: pickupLocation?.longitude ?? 0, latitude: pickupLocation?.latitude ?? 0 };
      // eslint-disable-next-line no-shadow
      const options: any = result.map((x) => ({
        category: x.category,
        id: x.id,
        name: x.name,
        phone: x.phoneNumber,
        photo: x.photoURL,
        isBusy: x.isBusy,
        // eslint-disable-next-line max-len, no-restricted-globals
        distance: isNaN(haversine({ longitude: x.location.longitude, latitude: x.location.latitude }, endCoordinate, { unit: 'meter' })) ? 0 : parseFloat((haversine({ longitude: x.location.longitude, latitude: x.location.latitude }, endCoordinate, { unit: 'meter' }) * 0.001).toFixed(2))
      }));
      setOptions(options);
      setOpen(true);
    });
  };

  const handleClose = (newDriverId?: string) => {
    setOptions([]);
    setOpen(false);
    if (newDriverId) {
      callService.changeDriverAsync(callId, newDriverId).then(() => {
        enqueueSnackbar('Chamada encaminhada com sucesso', { variant: 'success' });
      }).catch((e) => {
        enqueueSnackbar(e.message, { variant: 'error' });
      });
    }
  };

  let title = isEnabled ? 'Aguardando...' : 'Sem informação';
  let subtitle = isEnabled ? 'Nenhum entregador aceitou até o momento' : '...';
  if (status === 'imported') {
    title = 'Aguardando o preparo do pedido';
    subtitle = '...';
  }
  const buttonEnabled = isEnabled && status !== 'imported';

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <List component="div" role="group">
        <ListItem
          alignItems="flex-start"
          button={buttonEnabled}
          divider
          aria-haspopup="true"
          aria-controls="ringtone-menu"
          aria-label="phone ringtone"
          onClick={buttonEnabled ? handleClickListItem : undefined}
        >
          <ListItemAvatar>
            <Avatar alt="Cindy Baker" src={driver?.photo ?? ''} />
          </ListItemAvatar>
          <ListItemText
            primary={driver?.name ?? title}
            secondary={(
              <>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {phoneNumber(driver?.phone)}
                </Typography>
                {driver?.category ? ` - ${CategoryDriverText[driver.category]}` : subtitle}
              </>
            )}
          />
        </ListItem>
        {options?.length > 0 && (
          <Dialog open>
            <ConfirmationDialogRaw
              id="ringtone-menu"
              keepMounted
              options={options.map((x) => ({ key: x.id, value: `${x.name} (${CategoryDriverText[x.category]}) - ${x.distance}Km*`, disabled: x.id !== driver?.id && x.isBusy }))}
              open={open}
              onClose={handleClose}
              value={driver?.id ?? ''}
            />
          </Dialog>
        )}
      </List>
    </Box>
  );
}
