import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  Grid, Avatar, Button, Card, CardContent, Theme, TextField
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import { useAuth } from '../../contexts/auth';
import WrapperSimple from '../../layout-components/ExampleWrapperSimple';
import Dictionary from '../../models/interfaces/dictionary';
import callService from '../../services/callService';
import NoData from '../../components/noData';
import ModalComponent from './components/modalComponent';
import { getFirstDate, getLastDate } from '../../utils/dateHelper';
import { StatusCallList } from '../../models/enums/statusCall';
import { Row, customRow } from '.';
import { phoneNumber, toBrazilDate, toBrazilTime } from '../../components/mask';
import Call, { HistoryBy } from '../../models/interfaces/call';
import Loading from '../../components/loading';
import subsidiaryService from '../../services/subsidiaryService';

interface Formulario {
  subsidiary: string | null;
  statusCallList: Dictionary[] | null;
  startDate: Date | null;
  endDate: Date | null;
}

interface OwnProps {
  subsidiaryId: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selectRow: {
    cursor: 'pointer'
  }
}));

const page = (own: OwnProps) => {
  const [rows, setRows] = React.useState<Row[]>([]);
  const [total, setTotal] = React.useState(0);
  const current = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const [subsidiaries, setSubsidiaries] = React.useState<Dictionary[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [valuesForm, setValuesForm] = React.useState<Formulario>();
  let { subsidiaryId } = current;
  if (own.subsidiaryId) subsidiaryId = own.subsidiaryId;

  const owner: HistoryBy = {
    id: current.user?.uid ?? '?',
    name: current.user?.name ?? '?',
    photoURL: current.user?.photoURL ?? '?'
  };

  const classes = useStyles();
  const [details, setDetails] = React.useState<Call>();

  const [openModal, setOpenModal] = React.useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const handleOpenModal = (id: string) => {
    const item = _.find(rows, (x) => x.id === id);
    if (item) {
      setDetails(item as unknown as Call);
      toggleModal();
    }
  };

  useEffect(() => {
    // TODO: Implement infinit scroll
    subsidiaryService.getByPaginationAsync(1, 200).then((result) => {
      setSubsidiaries(result.data.map((x: any) => ({
        key: x.id,
        value: x.description
      })));
    });
  }, []);

  return (
    <>
      <WrapperSimple sectionHeading="Relatório de entregas">
        <Formik
          initialValues={{
            subsidiary: null,
            statusCallList: [],
            startDate: getFirstDate(),
            endDate: getLastDate()
          }}
          validationSchema={Yup.object().shape({
            subsidiary: subsidiaryId ? Yup.string().notRequired().nullable() : Yup.string().required().nullable(),
            statusCallList: Yup.array().required().min(1, 'Campo obrigatório'),
            startDate: Yup.date().required().nullable(),
            endDate: Yup.date().required().nullable()
          })}
          onSubmit={async (values: Formulario, actions) => {
            setIsLoading(true);
            values.endDate?.setSeconds(59);
            setValuesForm(values);

            let subsidiaryIdSelected = subsidiaryId;
            if (!subsidiaryIdSelected) {
              subsidiaryIdSelected = values.subsidiary;
            }

            callService.getAllReport(subsidiaryIdSelected, values.startDate!, values.endDate!, values.statusCallList!).then((report) => {
              const data = report.map((x: any) => customRow(x) as Row);
              setRows(data);
              const destinationsTotal = callService.getDestinationsCount(data);
              setTotal(destinationsTotal);
            }).catch((e) => {
              enqueueSnackbar(e.message, { variant: 'error' });
            }).finally(() => {
              actions.setSubmitting(false);
              setIsLoading(false);
            });
          }}
        >
          {(props: FormikProps<Formulario>) => {
            const {
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue
            } = props;

            return (
              <Form noValidate autoComplete="off">
                <Field type="hidden" name="address.ibge" value="" />
                <Grid container spacing={3}>
                  {subsidiaryId === undefined && (
                    <Grid item xs={12} sm={12} md={12}>
                      <Autocomplete
                        options={subsidiaries}
                        getOptionLabel={(option: Dictionary) => option.value}
                        onChange={(e, value) => setFieldValue('subsidiary', value!.key)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            label="Franquia"
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.subsidiary && touched.subsidiary)}
                            helperText={errors.subsidiary && touched.subsidiary && errors.subsidiary}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                      multiple
                      options={StatusCallList.filter((x) => ['canceled', 'complete'].includes(x.key))}
                      getOptionLabel={(option: any) => option.value}
                      disableCloseOnSelect
                      onChange={(e, value) => setFieldValue('statusCallList', value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Status"
                          variant="outlined"
                          placeholder="Selecione..."
                          onChange={handleChange}
                          autoFocus={subsidiaryId !== undefined}
                          fullWidth
                          error={Boolean(errors.statusCallList && touched.statusCallList)}
                          helperText={errors.statusCallList && touched.statusCallList && errors.statusCallList}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Início"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="startDate"
                      onChange={(date: Date | null) => { setFieldValue('startDate', date); }}
                      onBlur={handleBlur}
                      value={values.startDate}
                      error={Boolean(errors.startDate && touched.startDate)}
                      helperText={errors.startDate && touched.startDate && errors.startDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Fim"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="endDate"
                      onChange={(date: Date | null) => { setFieldValue('endDate', date); }}
                      onBlur={handleBlur}
                      value={values.endDate}
                      error={Boolean(errors.endDate && touched.endDate)}
                      helperText={errors.endDate && touched.endDate && errors.endDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} fullWidth>
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['far', 'keyboard']} />
                      </span>
                      <span className="btn-wrapper--label">Buscar</span>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </WrapperSimple>
      {
        isLoading ? <Loading /> : (
          <>
            {
              rows && (
                rows.length > 0 ? (
                  <Card className="card-box mb-4">
                    <ModalComponent open={openModal} toggle={toggleModal} data={details} owner={owner} />

                    <CardContent className="p-0">
                      <div className="table-responsive">
                        <table className="table table-hover text-nowrap mb-0">
                          <thead>
                            <tr>
                              <th className="bg-white text-center">Data</th>
                              <th className="bg-white text-left">Coleta/Destinos</th>
                              <th className="bg-white">Entregador</th>
                              <th className="bg-white text-center">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows?.length === 0 && <tr><td colSpan={6} className="text-center">Nenhuma informação encontrada</td></tr>}
                            {_.orderBy(rows, (x) => x.createdAt, 'desc')?.map((x) => (
                              <tr key={x.id} className={classes.selectRow} onClick={() => handleOpenModal(x.id)}>
                                <td className="text-center">
                                  <div>
                                    <div>{toBrazilDate(new Date(x.createdAt))}</div>
                                    <span>{toBrazilTime(new Date(x.createdAt))}</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <div>{x.clientName}</div>
                                      {x.destinations.map((d, i) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <span key={i} className="text-black-50 d-block">
                                          {_.truncate(d.address, { length: 100, separator: '...' })}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {x.driver && (
                                      <>
                                        <Avatar alt="" src={x.driver.photo} className="mr-2" />
                                        <div>
                                          <div>{x.driver.name}</div>
                                          <span className="text-black-50 d-block">{phoneNumber(x.driver.phone)}</span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td className="text-center">
                                  <div className={`h-auto py-0 px-3 badge badge-${x.statusHtml.color}`}>{x.statusHtml.description}</div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot className="font-weight-bold">
                            <tr>
                              <td colSpan={3} className="text-right">TOTAL</td>
                              <td className="text-center">{`${total} registros`}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </CardContent>
                  </Card>
                ) : <NoData message="Nenhuma informação encontrada." />
              )
            }
          </>
        )
      }
    </>
  );
};

export default page;
