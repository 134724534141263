import React from 'react';
import {
  StyleSheet, Document, Page, View, Text
} from '@react-pdf/renderer';

import Dictionary from '../../../../models/interfaces/dictionary';
import { toBrazilDate, toBrazilDateTime } from '../../../../components/mask';
import PaymentType from '../../../../models/enums/paymentType';
import { Row } from './billing';

interface OwnProps {
  data: Row[],
  percentage: number,
  paymentTypes: Dictionary[],
  startDate: Date | null | undefined,
  endDate: Date | null | undefined
}

export default function print(props: OwnProps) {
  const toDecimal = (value: number) => value?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const styles = StyleSheet.create({
    body: {
      padding: 10,
      paddingBottom: 60
    },
    table: {
      width: 'auto',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    colSmallHeader: {
      width: '11%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colLargeHeader: {
      width: '34%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colSmall: {
      width: '11%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colLarge: {
      width: '34%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCellHeader: {
      margin: 'auto',
      fontSize: 12,
      fontWeight: 500
    },
    tableCell: {
      fontSize: 10
    },
    tableMoneyCell: {
      fontSize: 10,
      textAlign: 'right'
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey'
    }
  });

  const getList = (list: Dictionary[]) => {
    let result = 'Custos presencias: ';
    const custosPresenciais = list.filter((x) => x.key !== PaymentType.Faturado);
    if (custosPresenciais.length === 0) result += 'Nenhum';
    else result += custosPresenciais.map((x) => x.value).join(', ');

    return result;
  };

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.header} fixed>
          {`${toBrazilDate(props.startDate)} a ${toBrazilDate(props.endDate)}\n${getList(props.paymentTypes)}`}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.colLargeHeader}>
              <Text style={styles.tableCellHeader}>Entregador</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Corridas</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Faturado</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>{`%\nFranquia\n(${props.percentage}%)`}</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Presencial</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>{`%\nFranquia\n(${props.percentage}%)`}</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Valor</Text>
            </View>
          </View>
          {props.data.map((x) => (
            <View style={styles.tableRow} key={x.id} wrap={false}>
              <View style={styles.colLarge}>
                <Text style={styles.tableCell}>{x.driverName}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{x.callsTotal}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{toDecimal(x.faturado)}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{toDecimal(x.faturadoPorcentagem)}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{toDecimal(x.ganhosPresenciais)}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{toDecimal(x.ganhosPresenciaisPorcentagem)}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{toDecimal(x.total)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}\n${toBrazilDateTime(new Date())}`
          )}
          fixed
        />
      </Page>
    </Document>
  );
}
