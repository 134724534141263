import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import {
  Fab, CircularProgress, Grid, FormControl, InputLabel, MenuItem, Select
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';

import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../../../components/styles/loadingButton';
import { CepMask, PhoneMask } from '../../../../components/mask';
import Address from '../../../../models/interfaces/address';
import { setAddressByCEP } from '../../../../services/geolocateService';
import Dictionary from '../../../../models/interfaces/dictionary';
import subsidiaryService from '../../../../services/subsidiaryService';
import userService from '../../../../services/userService';
import {
  // eslint-disable-next-line max-len
  CallByList, CalcRouteList, CalcRoute, CallBy, FinishDelivery, DisabledPaymentToClientList, FinishDeliveryList, AutoComplete, OptionsCancelsToBlockDriver, TimeBlockDriverByCancels, TimeZones
} from '../../../../models/enums/settings';
import { SubsidiarySettings } from '../../../../models/interfaces/subsidiary';

interface ParamTypes {
  id: string
}

interface Values {
  id: string;
  description: string;
  email: string;
  address: Address;
  owner?: Dictionary;
  phoneNumber: string | null;
  disabledAt: Date | null;
  settings: SubsidiarySettings;
}

export default function page() {
  const history = useHistory();
  const classes = UseStyles();
  const { id } = useParams<ParamTypes>();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [users, setUsers] = React.useState<Dictionary[]>();

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  const onBlurCep = (ev: any, setFieldValue: any): void => {
    setAddressByCEP(ev, setFieldValue).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'warning' });
    });
  };

  useEffect(() => {
    // TODO: Implement infinite scroll on dropdown
    userService.getByPaginationAsync(1, 200, 'manager').then((result) => {
      const data = result.data.map((x: any) => ({ key: x.id, value: x.name }));
      setUsers(data);
    });
  }, []);

  return (
    <WrapperSimple sectionHeading="Editar franquia">
      <Formik
        initialValues={{
          id,
          description: '',
          email: '',
          address: {
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: null,
            ibge: null,
            location: null
          },
          phoneNumber: null,
          settings: {
            calcRoute: CalcRoute.Fulled,
            callBy: CallBy.Radius,
            fee: 0,
            disabledPaymentToClient: true,
            finishDelivery: FinishDelivery.Anywhere,
            useAutoComplete: AutoComplete.Boaztech,
            ownAutoCompleteList: false,
            cancelsToBlock: 0,
            timeBlocked: 15,
            timeZone: null,
            directionsProvider: null,
            qtyNearDriversFromQueue: null
          },
          disabledAt: null
        }}
        validationSchema={Yup.object().shape({
          owner: Yup.object().nullable().required(),
          description: Yup.string().required(),
          email: Yup.string().email().required(),
          phoneNumber: Yup.string().nullable()
            .matches(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, 'Telefone inválido'),
          settings: Yup.object().shape({
            calcRoute: Yup.string().required(),
            callBy: Yup.string().required(),
            fee: Yup.number().required(),
            disabledPaymentToClient: Yup.boolean().required(),
            finishDelivery: Yup.string().required(),
            cancelsToBlock: Yup.string().required(),
            timeBlocked: Yup.string().required()
          }),
          address: Yup.object().shape({
            zipCode: Yup.string().required(),
            street: Yup.string().required(),
            neighborhood: Yup.string().required(),
            city: Yup.string().required(),
            number: Yup.string().required()
          })
        })}
        onSubmit={async (values: Values, actions) => {
          await subsidiaryService.updateAsync(values, values.id).then(() => {
            setSuccess(true);
            history.goBack();
          }).catch((e: any) => {
            enqueueSnackbar(e.message, { variant: 'error' });
            setSuccess(false);
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<Values>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;

          useEffect(() => {
            subsidiaryService.getByIdAsync(id).then(async (item: any) => {
              const fields = ['description', 'email', 'address', 'phoneNumber', 'settings'];
              fields.forEach((field) => setFieldValue(field, item[field]));

              setFieldValue('owner', { key: item.owner.id, value: item.owner.name });

              setIsLoading(false);
            }).catch(() => {
              history.push('/404');
            });
          }, []);

          return (isLoading
            ? (
              <div className="d-flex align-items-center justify-content-center" style={{ height: '360px' }}>
                <ScaleLoader color="var(--primary)" loading />
              </div>
            )
            : (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={7}>
                    {users?.length && (
                      <Autocomplete
                        options={users}
                        getOptionLabel={(option: Dictionary) => option.value}
                        defaultValue={values.owner}
                        getOptionSelected={(x) => x.key === values.owner?.key}
                        onChange={(event, newInputValue) => {
                          setFieldValue('owner', newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            variant="outlined"
                            label="Proprietário"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoFocus
                            error={Boolean(errors.owner && touched.owner)}
                            helperText={errors.owner && touched.owner && errors.owner}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Taxa da franquia (% descontado do entregador)"
                      name="settings.fee"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.settings?.fee && touched.settings?.fee)}
                      helperText={errors.settings?.fee && touched.settings?.fee && errors.settings?.fee}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={7}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      label="Descrição"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values.description}
                      error={Boolean(errors.description && touched.description)}
                      helperText={errors.description && touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values.email}
                      error={Boolean(errors.email && touched.email)}
                      helperText={errors.email && touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="CEP"
                      name="address.zipCode"
                      onChange={handleChange}
                      onBlur={(e: any) => onBlurCep(e, setFieldValue)}
                      error={Boolean(errors.address?.zipCode && touched.address?.zipCode)}
                      helperText={errors.address?.zipCode && touched.address?.zipCode && errors.address?.zipCode}
                      InputProps={{ inputComponent: CepMask as any }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Endereço"
                      name="address.street"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.address?.street && touched.address?.street)}
                      helperText={errors.address?.street && touched.address?.street && errors.address?.street}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Bairro"
                      name="address.neighborhood"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.address?.neighborhood && touched.address?.neighborhood)}
                      helperText={errors.address?.neighborhood && touched.address?.neighborhood && errors.address?.neighborhood}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Cidade"
                      name="address.city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.address?.city && touched.address?.city)}
                      helperText={errors.address?.city && touched.address?.city && errors.address?.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Field
                      as={TextField}
                      fullWidth
                      required
                      variant="outlined"
                      label="Nº"
                      name="address.number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.address?.number && touched.address?.number)}
                      helperText={errors.address?.number && touched.address?.number && errors.address?.number}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Field
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      label="Complemento"
                      name="address.complement"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.address?.complement && touched.address?.complement)}
                      helperText={errors.address?.complement && touched.address?.complement && errors.address?.complement}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <Field
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      label="Telefone"
                      name="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values.phoneNumber}
                      error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                      helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                      InputProps={{ inputComponent: PhoneMask as any }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Método de chamada dos entregadores</InputLabel>
                      <Select
                        label="Método de chamada dos entregadores"
                        name="settings.callBy"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.callBy}
                        error={Boolean(errors.settings?.callBy && touched.settings?.callBy)}
                      >
                        {CallByList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Cálculo da rota</InputLabel>
                      <Select
                        label="Cálculo da rota"
                        name="settings.calcRoute"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.calcRoute}
                        error={Boolean(errors.settings?.calcRoute && touched.settings?.calcRoute)}
                      >
                        {CalcRouteList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Exibe tipo de pagto no cliente</InputLabel>
                      <Select
                        label="Exibe tipo de pagto no cliente"
                        name="settings.disabledPaymentToClient"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.disabledPaymentToClient}
                        error={Boolean(errors.settings?.disabledPaymentToClient && touched.settings?.disabledPaymentToClient)}
                      >
                        {DisabledPaymentToClientList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Entregador pode finalizar em (Entrega sem retorno)</InputLabel>
                      <Select
                        label="Entregador pode finalizar em (Entrega sem retorno)"
                        name="settings.finishDelivery"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.finishDelivery}
                        error={Boolean(errors.settings?.finishDelivery && touched.settings?.finishDelivery)}
                      >
                        {FinishDeliveryList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Inibir entregador após quantos cancelamentos?</InputLabel>
                      <Select
                        label="Inibir entregador após quantos cancelamentos?"
                        name="settings.cancelsToBlock"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.cancelsToBlock}
                        error={Boolean(errors.settings?.cancelsToBlock && touched.settings?.cancelsToBlock)}
                      >
                        {OptionsCancelsToBlockDriver.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Tempo de espera para entregador inibido</InputLabel>
                      <Select
                        label="Tempo de espera para entregador inibido"
                        name="settings.timeBlocked"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.timeBlocked}
                        error={Boolean(errors.settings?.timeBlocked && touched.settings?.timeBlocked)}
                      >
                        {TimeBlockDriverByCancels.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Fuso Horário</InputLabel>
                      <Select
                        label="Fuso Horário da cidade da franquia"
                        name="settings.timeZone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.timeZone}
                        error={Boolean(errors.settings?.timeZone && touched.settings?.timeZone)}
                      >
                        {TimeZones.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Autocomplete provider</InputLabel>
                      <Select
                        disabled
                        label="Autocomple provider"
                        name="settings.useAutoComplete"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.useAutoComplete}
                        error={Boolean(errors.settings?.useAutoComplete && touched.settings?.useAutoComplete)}
                      >
                        {AutoCompleteList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Own autocomplete list</InputLabel>
                      <Select
                        disabled
                        label="Own autocomplete list"
                        name="settings.ownAutoCompleteList"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={values.settings!.ownAutoCompleteList}
                        error={Boolean(errors.settings?.ownAutoCompleteList && touched.settings?.ownAutoCompleteList)}
                      >
                        {OwnAutoCompleteList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end" spacing={3}>
                      <div className={classes.wrapper}>
                        <Fab
                          aria-label="save"
                          color="primary"
                          className={buttonClassname}
                          type="submit"
                        >
                          {success ? <CheckIcon /> : <SaveIcon />}
                        </Fab>
                        {isSubmitting && (
                          <CircularProgress size={68} className={classes.fabProgress} />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
